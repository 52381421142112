// src/apiConfig.js
//old API
// const baseUrl = "https://e-citizen.ng:8443/api/v2";

//New API
// const baseUrl = "https://e-citizen.ng:8443/api/v2";

const baseUrl = "https://e-citizen.ng:8443/api/v2";

export default baseUrl;
